import React from 'react'

import { Layout, ReservationForm, SEO, RouteCard } from '@/components'
import { useFetchRoutes } from '@/hooks'

export const Routes = ({ pageContext: { seo } }) => {
  const routes = useFetchRoutes()
  return (
    <Layout>
      <SEO
        description={seo.metaDesc}
        title={seo.title}
        meta={[
          {
            name: `keywords`,
            content: seo.focuskw,
          },
        ]}
      />
      <section className="section-first">
        <div className="container">
          <h1 className="title_h2">Наші маршрути</h1>
        </div>
        <section className="routes">
          <div className="container">
            <div className="routes__list">
              {routes.map((route) => (
                <RouteCard key={route.id} data={route} />
              ))}
            </div>
          </div>
        </section>
      </section>
      <ReservationForm />
    </Layout>
  )
}
